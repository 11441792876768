var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 85px"
      },
      form: _vm.editorForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "标签名称"
    }
  }, [_c("div", {
    staticClass: "input-num-tip",
    attrs: {
      "data-num": "".concat((_vm.editorForm.getFieldValue("tagname") || "").length, "/50")
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["tagname", {
        rules: [{
          required: true,
          message: "请输入标签名称!"
        }, {
          type: "string",
          max: 50,
          message: "不能超过50个字符."
        }],
        initialValue: _vm.tagname
      }],
      expression: "[\n              'tagname',\n              {\n                rules: [{ required: true, message: '请输入标签名称!' }, {type: 'string', max: 50, message: '不能超过50个字符.'}],\n                initialValue: tagname,\n              },\n            ]"
    }],
    attrs: {
      autoComplete: "off",
      size: "large",
      placeholder: "请输入",
      maxLength: 50
    }
  })], 1)]), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#f5f5f5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.loading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };