import "core-js/modules/es.object.to-string.js";
import Mixin from "@/utils/mixin";
import { searchKeys, columns } from "./config/index";
import MediaForm from "./components/Form";
import { SysAccountApi } from "@/api";
export default {
  components: {
    MediaForm: MediaForm
  },
  data: function data() {
    return {
      editId: '',
      editItem: {},
      loading: false,
      searchKeys: searchKeys,
      createForm: this.$form.createForm(this),
      createFormValues: {},
      columns: columns,
      isDeleteVisible: false,
      deleting: false,
      dataSource: [],
      tipsDesc: '',
      modalTitle: '新增/编辑',
      addVersionVisible: false,
      updateStatus: 0
    };
  },
  mixins: [Mixin],
  computed: {
    getDataWithKey: function getDataWithKey() {
      return function (key) {
        return {};
      };
    }
  },
  created: function created() {
    this.getList();
  },
  activated: function activated() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.loading = true;
      var params = {};
      SysAccountApi.tagList(params).then(function (data) {
        _this.loading = false;
        _this.dataSource = typeof data == 'boolean' ? [] : data;
      }).catch(function () {
        _this.dataSource = [];
      }).finally(function (e) {
        setTimeout(function () {
          _this.loading = false;
        }, 500);
      });
    },
    handleEdit: function handleEdit(item) {
      this.editId = item.tagid;
      this.editItem = item;
      this.modalTitle = '编辑';
      this.addVersionVisible = true;
    },
    showPopupForm: function showPopupForm() {
      this.editId = '';
      this.editItem = {};
      this.modalTitle = '新增';
      this.addVersionVisible = true;
    },
    handleAddCancle: function handleAddCancle() {
      this.addVersionVisible = false;
    },
    handleDelete: function handleDelete(item) {
      this.editId = item.tagid;
      this.tipsDesc = "确认删除吗？";
      this.isDeleteVisible = true;
    },
    cancel: function cancel() {
      this.isDeleteVisible = false;
      this.releaseVisible = false;
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;
      var params = {
        tagid: this.editId
      };
      this.deleting = true;
      SysAccountApi.tagDel(params).then(function (res) {
        if (res) {
          _this2.isDeleteVisible = false;
          _this2.getList();
        }
      }).catch(function () {}).finally(function () {
        _this2.deleting = false;
      });
    }
  }
};